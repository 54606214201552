import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export default function Tiime({ size = 40, color }: Props) {
  const st0 = {
    fill: color ?? "#FF7483",
  };
  const st1 = {
    fill: color ?? "#8089FF",
  };
  const st2 = {
    fill: color ?? "#FF8765",
  };
  const st3 = {
    fill: color ?? "#54CEFF",
  };
  const st4 = {
    fill: color ?? "#FFD41D",
  };
  const st5 = {
    fill: color ?? "#19D9B4",
  };

  return (
    <svg
      className="icon"
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      width={size + "px"}
      color={color}
    >
      <g>
        <path
          style={st0}
          d="M25.1,32.2L50,17.8l16.7-9.6l-12-6.9c-2.9-1.7-6.5-1.7-9.3,0L25.1,12.9L25.1,32.2L25.1,32.2z"
        />
        <path style={st1} d="M22.1,62.6v-48l-12,6.9c-2.9,1.7-4.7,4.8-4.7,8.1V53L22.1,62.6z" />
        <path style={st2} d="M53,19.5l24.9,14.4l16.7,9.6V29.7c0-3.3-1.8-6.4-4.7-8.1L69.7,9.9L53,19.5L53,19.5z" />
        <path style={st3} d="M47,80.5L22.1,66.1L5.4,56.5v13.9c0,3.3,1.8,6.4,4.7,8.1l20.2,11.7L47,80.5z" />
        <path style={st4} d="M77.9,37.4v48l12-6.9c2.9-1.7,4.7-4.8,4.7-8.1V47L77.9,37.4L77.9,37.4z" />
        <path style={st5} d="M74.9,67.8L50,82.2l-16.7,9.6l12,6.9c2.9,1.7,6.5,1.7,9.3,0l20.2-11.7V67.8z" />
      </g>
    </svg>
  );
}
